import { render, staticRenderFns } from "./Ems.vue?vue&type=template&id=13ce4b00&scoped=true"
import script from "./Ems.vue?vue&type=script&lang=js"
export * from "./Ems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ce4b00",
  null
  
)

export default component.exports