<template>
  <v-container class="mt-5">
    <v-card class="mx-auto">
      <v-card-title> Event {{ eventNo }} Delegates </v-card-title>
      <v-card-text>
        <v-alert
          v-if="!!alert.status"
          border="left"
          :type="alert.status"
          class="mb-5"
        >
          {{ alert.message }}
        </v-alert>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="header in headers" :key="header.text">
                  {{ header.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in delegates.data" :key="item.id">
                <td>{{ item.delegateNo }}</td>
                <td>{{ item.idNo }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.phoneNo }}</td>
                <td>
                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    :disabled="item.fingerprintExists"
                    @click="scan(item)"
                  >
                    Register Fingerprint
                    <v-icon>mdi-fingerprint</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <PaginationComponent
          v-if="delegates.total"
          :current-cursor="delegates.currentCursor"
          :next-cursor="delegates.nextCursor"
          @pagination-change-page="fetchData"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import PaginationComponent from "../../../components/PaginationComponent.vue";

export default {
  name: "DelegatesBio",
  components: { PaginationComponent },
  data: function () {
    return {
      headers: [
        {
          text: "#No",
          align: "start",
          sortable: false,
          value: "delegateNo",
        },
        { text: "Id Number", value: "idNo" },
        { text: "Name", value: "name" },
        { text: "email", value: "email" },
        { text: "phoneNo", value: "phoneNo" },
        { text: "Action", value: "action" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("Biometric/delegates", this.eventNo);
  },
  computed: {
    delegates() {
      return this.$store.getters["Biometric/bioGetters"]("delegates");
    },
    eventNo() {
      return this.$route.params.event;
    },
    alert() {
      return this.$store.getters["Biometric/bioGetters"]("alert");
    },
  },
  methods: {
    scan(item) {
      this.$store.dispatch("Biometric/registerFingerprint", item);
    },
  },
};
</script>
