<script>
import SigmaPaginationWrapper from "./SigmaPaginationWrapper.vue";

export default {
  name: "PaginationComponent",
  components: { SigmaPaginationWrapper },
  props: ["currentCursor", "nextCursor"],
};
</script>

<template>
  <sigma-pagination-wrapper
    :current-cursor="currentCursor"
    :next-cursor="nextCursor"
    @pagination-change-page="$emit('pagination-change-page', $event)"
    v-slot="{ computed, nextButtonEvents, prevButtonEvents }"
  >
    <nav class="d-flex align-items-center justify-content-between mt-3">
      <div>
        <button
          :class="{ disabled: computed.isFirstPage }"
          class="btn d-flex align-items-center"
          v-on="prevButtonEvents"
        >
          <svg
            width="24"
            height="20"
            viewBox="0 0 20 16"
            fill="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M9.3335 12.5L5.3335 8.5L9.3335 4.5L10.2668 5.43333L7.20016 8.5L10.2668 11.5667L9.3335 12.5Z"
            />
          </svg>
          Previous
        </button>
      </div>

      <div class="ml-6">
        <button
          :class="{ disabled: computed.isLastPage }"
          class="btn d-flex align-items-center"
          href="#"
          v-on="nextButtonEvents"
        >
          <span>Next</span>
          <svg
            width="24"
            height="20"
            viewBox="0 0 20 16"
            fill="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M6.26683 12.5L5.3335 11.5667L8.40016 8.5L5.3335 5.43333L6.26683 4.5L10.2668 8.5L6.26683 12.5Z"
            />
          </svg>
        </button>
      </div>
    </nav>
  </sigma-pagination-wrapper>
</template>

<style scoped lang="scss"></style>
