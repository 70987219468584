export default {
  events: (params) => `event?${formatParams(params)}`,
  event: (no) => `event/${no}/details`,
  ems_events: (year) => `ems${year ? "/" + year : ""}`,
  sync: (code) => `ems/sync-delegates/${code}`,
  mapEmsToBC: "ems/map-ems-to-bc",
  eventTypes: "event/types",
  uploadExcel: (code) => `ems/import-delegates/${code}`,
  delegates: (params) => `event/delegate?${formatParams(params)}`,
  attendance: "event/attendance",
  report: "event/report",
  qrTag: "event/qr-tag",
  users: "auth/users",
  saveUser: "auth/register",
  remove: "auth/user_remove",
  getAttendance: "event/getAttendance",
  getDelegate: (params) => `event/get-delegate?${formatParams(params)}`,
  packageStats: (params) => `event/package-stats?${formatParams(params)}`,
};

function formatParams(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}
