<template>
  <v-container class="mt-5">
    <v-card flat class="mx-auto" v-if="certificate">
      <v-card-text>
        <div
          class="d-flex justify-lg-center text-wrap flex-column align-center"
        >
          <div class="black--text text-lg-h4 text-h6 text--secondary">
            Certificate of Attendance
          </div>
          <div class="text-lg-h5">
            {{ certificate.certificateNo }}&ndash;
            {{ certificate.description }}
          </div>
          <div class="my-2 text-lg-body-1 text-caption font-weight-light">
            Certificate issued to
            <span class="black--text text-lg-subtitle-1 font-weight-medium">
              {{ certificate.name }}, {{ certificate.memberNo }} ,
              {{ certificate.email }}
            </span>
            on
            <span class="black--text text-lg-subtitle-1 font-weight-medium">
              {{ getDateFormat(certificate.dateTimeSent) }}
            </span>
            for the event
            <span class="black--text text-lg-subtitle-1 font-weight-medium">
              {{ certificate.description }}
            </span>
            with
            <span class="black--text text-lg-subtitle-1 font-weight-medium">
              {{ certificate.cpdHours }}
            </span>
            CPD Hours
          </div>
        </div>
        <pdf
          style="width: 100%"
          :src="`data:application/pdf;base64,${certificate.certificate}`"
        />
      </v-card-text>
      <v-card-actions class="mb-10">
        <v-spacer />
        <v-btn
          color="primary"
          @click="
            downloadFile(
              certificate.certificate,
              `${certificate.certificateNo}.pdf`
            )
          "
        >
          Download
          <v-icon class="mx-2">mdi-download</v-icon>
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-skeleton-loader type="card" height="80vh" />
    </v-card>
  </v-container>
</template>
<script>
import FileMixin from "../../mixins/FileMixin";
import pdf from "vue-pdf";
import { helper } from "@/utils/";
export default {
  name: "CertVerify",
  mixins: [FileMixin],
  components: {
    pdf,
  },
  data: function () {
    return {
      certificateNo: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.certificateNo = atob(to.params.certificateNo);
      v.$store.dispatch("Landing/getCertificate", {
        certificateNo: v.certificateNo,
      });
    });
  },
  computed: {
    certificate() {
      return this.$store.getters["Landing/certificate"];
    },
  },
  methods: {
    getDateFormat: function (date) {
      return helper.dateFix(date);
    },
  },
};
</script>
