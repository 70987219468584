<template>
  <v-container>
    <v-sheet min-height="70vh" rounded="lg" class="pa-5">
      <v-btn-toggle
        color="primary"
        class="mb-3"
        dense
        v-model="searchEventType"
        @change="filterEvents"
      >
        <v-btn value="">All</v-btn>

        <v-btn v-for="(item, i) in eventTypes" :key="i" :value="item.code">
          <span class="hidden-sm-and-down">{{ item.code | capitalize }}</span>
        </v-btn>
      </v-btn-toggle>

      <v-simple-table>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> CPD Events </v-toolbar-title>

            <v-spacer />

            <v-text-field
              class="mt-10"
              v-model="searchDescription"
              placeholder="Search by event name..."
              @input="searchEvents"
              dense
            >
              <template v-slot:append>
                <v-icon left>mdi-search</v-icon>
              </template>
            </v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="header in headers" :key="header.text">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in events.data" :key="item.id">
              <td>{{ item.description }}</td>
              <td>{{ item.eventType }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.location }}</td>
              <td>{{ formatDate(item.startDate, 6) }}</td>
              <td>{{ formatDate(item.endDate, 6) }}</td>
              <td>
                <v-btn
                  :to="{ name: 'Event', params: { code: item.no } }"
                  color="primary"
                  tile
                >
                  Select
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <PaginationComponent
        v-if="events.total"
        :current-cursor="events.currentCursor"
        :next-cursor="events.nextCursor"
        @pagination-change-page="fetchData"
      />
    </v-sheet>
  </v-container>
</template>

<script>
import DateMixin from "@/modules/mixins/DateMixin";
import PaginationComponent from "../../../components/PaginationComponent.vue";
import { helper } from "@/utils/";

export default {
  name: "Dashboard",
  components: { PaginationComponent },
  mixins: [DateMixin],
  data: function () {
    return {
      searchDescription: "",
      searchEventType: "",
      headers: [
        {
          text: "Event name",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "Type", value: "eventType" },
        { text: "Status", value: "status" },
        { text: "Location", value: "location" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
        { text: "Action", value: "action" },
      ],
      debouncedFetchData: () => {},
    };
  },
  mounted() {
    this.$store.dispatch("Dashboard/getEventTypes");
    this.fetchData();
    this.debouncedFetchData = helper.debounce(this.fetchData, 1000);
  },
  computed: {
    events() {
      return this.$store.getters["Dashboard/events"];
    },

    eventTypes() {
      return this.$store.getters["Dashboard/eventsTypes"].filter((type) => {
        return !type.internalEvent;
      });
    },
  },
  methods: {
    fetchData(cursor) {
      this.$store.dispatch("Dashboard/getEvents", {
        cursor: cursor || "",
        description: this.searchDescription || "",
        eventType: this.searchEventType || "",
      });
    },
    searchEvents() {
      this.debouncedFetchData();
    },
    filterEvents() {
      this.fetchData();
    },
  },
};
</script>

<style scoped></style>
