<template>
  <v-container class="mt-5">
    <v-card flat class="mx-auto" v-if="certificate">
      <v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          @click="downloadFile(certificate, `Certificate.pdf`)"
        >
          Download
          <v-icon class="mx-2">mdi-download</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <pdf
          style="width: 100%"
          :src="`data:application/pdf;base64,${certificate}`"
        />
      </v-card-text>
      <v-card-actions class="mb-10">
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-skeleton-loader type="card" height="80vh" />
    </v-card>
  </v-container>
</template>
<script>
import FileMixin from "../../mixins/FileMixin";
import pdf from "vue-pdf";
import { helper } from "@/utils/";
export default {
  name: "ProviderVerify",
  mixins: [FileMixin],
  components: {
    pdf,
  },
  data: function () {
    return {
      certificateNo: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      const parameters = atob(to.params.parameters);
      v.$store.dispatch("Landing/getVerification", {
        eventNo: parameters.split(":")[0],
        providerID: parameters.split(":")[1],
      });
    });
  },
  computed: {
    certificate() {
      return this.$store.getters["Landing/verification"];
    },
  },
  methods: {
    getDateFormat: function (date) {
      return helper.dateFix(date);
    },
  },
};
</script>
