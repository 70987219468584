import client from "./client/client";

export default async function call(
  requestType,
  url,
  data = null,
  options = {}
) {
  return client[requestType](url, data, options);
}
