<template>
  <v-container class="mt-5" :fluid="fullscreen">
    <v-card color="grey lighten-5" elevation="0">
      <v-card-title class="text-uppercase overline">
        <v-btn class="mr-3" @click="$router.back()" text>
          <v-icon left>mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <v-spacer />

        {{
          event
            ? `${event.description} , ${formatDate(
                event.startDate,
                6
              )} - ${formatDate(event.endDate, 6)}, ${event.location}`
            : "" | capitalize("also this")
        }}

        <v-spacer />

        <v-btn text @click="search = !search" :color="search ? 'info' : ''">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn
          text
          @click="
            $store.dispatch('Dashboard/getEventDelegates', {
              eventCode: $route.params.event,
              packageCode: $route.params.package,
            })
          "
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn
          text
          @click="toggleFullscreen()"
          :color="fullscreen ? 'info' : ''"
        >
          <v-icon
            >{{ fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-row class="pa-5">
        <v-col cols="12" md="3">
          <v-card elevation="3" dark color="primary">
            <v-card-title class="overline">
              {{
                event
                  ? event.eventPackageCost.length > 1
                    ? event.description
                    : event.eventPackageCost[0].description
                  : ""
              }}
              Day {{ day }} of {{ event ? event.eventDurationDays : "" }}
            </v-card-title>

            <v-card-actions class="overline">
              Delegates
              <v-spacer />
              <v-chip
                dark
                color="primary lighten-5"
                text-color="primary darken-4"
              >
                {{ delegates.total }}
              </v-chip>
            </v-card-actions>

            <v-divider />

            <v-card-actions class="overline">
              Checked In
              <v-spacer />
              <v-chip
                dark
                color="primary lighten-5"
                text-color="primary darken-4"
              >
                {{ checkedStats ? checkedStats.totalAttendance : "-" }}
              </v-chip>
            </v-card-actions>
          </v-card>

          <v-card elevation="3" class="mt-5">
            <v-card-title class="overline">Settings</v-card-title>
            <v-card-text>
              <v-switch
                title="Auto Checkin on scan"
                flat
                label="Auto Checkin"
                dense
                inset
                color="primary"
                v-model="autoCheck"
                hide-details
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <v-card elevation="3">
            <v-card-text v-if="search" class="grey lighten-3 px-10">
              <v-btn
                @click="search = false"
                color="info"
                x-small
                text
                style="position: absolute; top: 2px; right: 2px"
                class="ma-3"
              >
                hide search
              </v-btn>

              <v-text-field
                autofocus
                label="Search delegate"
                class="mt-10"
                outlined
                placeholder="Delegate No."
                append-icon="mdi-magnify"
                v-model="term"
                @focus="userInput = true"
                @blur="userInput = false"
                @keyup.enter="fetchData()"
                clearable
              />
            </v-card-text>

            <v-card-text style="min-height: 20px">
              <v-text-field
                style="position: absolute; top: -1000px"
                @keyup.enter="onScan()"
                ref="input"
                autofocus
                v-model="scanned"
              />

              <v-expand-transition>
                <v-alert
                  v-if="!!$store.getters['Dashboard/alert'].status"
                  border="left"
                  :type="$store.getters['Dashboard/alert'].status"
                  class="mb-5"
                >
                  {{ $store.getters["Dashboard/alert"].message }}
                </v-alert>
              </v-expand-transition>
            </v-card-text>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="header in headers" :key="header.text">
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in delegates.data" :key="item.id">
                      <td>{{ item.delegateNo }}</td>
                      <td>{{ item.eventDelegate.name }}</td>
                      <td>{{ item.eventDelegate.email }}</td>
                      <td>
                        <v-chip
                          x-small
                          color="secondary darken-2"
                          text-color="white"
                        >
                          {{ item.consumedUnits }}
                          /
                          {{ item.units }}
                        </v-chip>
                      </td>
                      <td>
                        <v-btn-toggle>
                          <v-btn
                            @click="checkIn(item)"
                            icon
                            small
                            :disabled="disabledBtn(item)"
                          >
                            <v-icon color="secondary">mdi-arrow-right</v-icon>
                          </v-btn>

                          <v-btn
                            @click="
                              getTag(
                                'qrCodeEventTag',
                                item.eventDelegate.delegateNo
                              )
                            "
                            icon
                            small
                          >
                            <v-icon color="primary">mdi-qrcode</v-icon>
                          </v-btn>

                          <v-btn @click="getFingerprint(item)" icon small>
                            <v-icon color="primary">mdi-fingerprint</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <PaginationComponent
                v-if="delegates.total"
                :current-cursor="delegates.currentCursor"
                :next-cursor="delegates.nextCursor"
                @pagination-change-page="fetchData"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
      style="z-index: 10000000"
    >
      <v-card v-if="dialog" class="pa-5">
        <v-card-title class="text-h6 text-capitalize" v-if="!hasCheckedIn">
          Checkin {{ delegate.eventDelegate.name | capitalize }} ?
        </v-card-title>

        <v-card-title v-else>
          <v-icon color="success">mdi-check-circle</v-icon>
          {{ delegate.eventDelegate.name | capitalize }} has Checked In for
          {{ packageDescription }}
        </v-card-title>

        <v-card-text class="overline">
          <strong class="font-weight-black">Package</strong> :
          {{
            event.eventPackageCost.length > 1
              ? event.description
              : packageDescription
          }}
          <br />
          <strong class="font-weight-black">Units</strong>:
          {{ delegate.consumedUnits }}
          /
          {{ delegate.units }}
        </v-card-text>

        <v-card-actions v-if="!hasCheckedIn">
          <v-spacer></v-spacer>
          <v-btn large color="grey" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn large color="green darken-1" dark @click="submit">
            Checkin
            <v-icon right>mdi-account-check</v-icon>
          </v-btn>
        </v-card-actions>

        <v-card-actions v-else>
          <v-spacer />
          <v-btn large color="green darken-1" dark @click="dialog = false">
            Ok
            <v-icon right>mdi-check-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DateMixin from "@/modules/mixins/DateMixin";
import moment from "moment";
import FileMixin from "@/modules/mixins/FileMixin";
import { AuthService } from "@/modules/auth";
import PaginationComponent from "@/components/PaginationComponent.vue";

export default {
  name: "Delegates",
  components: { PaginationComponent },
  mixins: [DateMixin, FileMixin],
  data: function () {
    return {
      autoCheck: false,
      dialog: false,
      delegate: {},
      scanned: "",
      term: "",
      prevTerm: "",
      fullscreen: false,
      search: false,
      userInput: false,
      checkedStats: {},
    };
  },

  mounted() {
    this.fetchData();
    this.packageStats();
    setInterval(() => {
      if (this.$refs.input && !this.userInput) this.$refs.input.focus();
    }, 1000);

    Event.$on("delegate-checked-in", () => {
      this.$store.commit("Dashboard/SET_ALERT", {
        status: "success",
        message: `${this.delegate.eventDelegate.name} is checked in successfully`,
      });
    });

    Event.$on("checkInDelegate", (data) => {
      this.checkIn(data);
    });

    Event.$on("scanned-delegate", (data) => {
      let delegate = data;
      this.delegate = delegate[0];
      if (delegate.length === 1) {
        delegate = delegate[0];

        if (this.hasCheckedIn) {
          this.$store.commit("Dashboard/SET_ALERT", {
            status: "info",
            message: `${delegate.eventDelegate.name} has already checked in for ${this.packageDescription}`,
          });

          return 0;
        }

        if (this.autoCheck) {
          this.submit();
        } else {
          this.checkIn({ ...this.delegate });
        }
      } else {
        this.$store.commit("Dashboard/SET_ALERT", {
          status: "warning",
          message: `Delegate not found`,
        });
      }
    });

    Event.$on("package-stats", (data) => {
      this.checkedStats = data.filter(
        (stat) => stat.packageCode === this.$route.params.package
      )[0];
    });
  },

  computed: {
    headers() {
      return [
        {
          text: "Delegate No.",
          align: "start",
          sortable: false,
          value: "delegateNo",
        },
        { text: "Name", value: "eventDelegate.name" },
        { text: "Email", value: "eventDelegate.email" },
        { text: "Attendance", value: "units" },
        { text: "Action", value: "action" },
      ];
    },

    delegates: function () {
      return this.$store.getters["Dashboard/delegateS"];
    },

    user() {
      return AuthService.user;
    },

    canCheckin() {
      return (
        moment() >= moment(this.event.startDate) &&
        moment() <= moment(this.event.endDate)
      );
    },

    event() {
      return this.$store.getters["Dashboard/event"];
      // let eventsWithPackage = JSON.parse(JSON.stringify(events));

      // return eventsWithPackage
      //   .map((e) => {
      //     e.eventPackageCost = e.eventPackageCost.filter(
      //       (p) => p.packageNo === this.$route.params.package
      //     );
      //     return e;
      //   })
      //   .shift();
    },
    packageDescription() {
      const packg = this.event.eventPackageCost.find((p) => {
        return p.packageNo === this.$route.params.package;
      });
      return packg.description;
    },
    day() {
      if (!this.event) return 0;

      let diff = moment().diff(moment(this.event.startDate), "days");

      if (diff < 0) return 0;

      if (diff > this.event.eventDurationDays)
        return this.event.eventDurationDays;

      return diff + 1;
    },

    hasCheckedIn() {
      if (!this.delegate.eventDelegate.delegateAttendance) return false;

      return (
        this.delegate.eventDelegate.delegateAttendance.filter((d) => {
          return (
            moment(d.consumedDate).diff(moment(), "days") === 0 &&
            d.packageCode === this.$route.params.package
          );
        }).length > 0
      );
    },

    qrTag() {
      return this.$store.getters["Dashboard/qrTag"];
    },
  },

  methods: {
    disabledBtn(item) {
      return (
        item.units <= item.consumedUnits
        // this.event.eventPackageCost.find(
        //   (p) => (p.packageNo = this.$route.params.package)
        // ).checkedInUnits
        // item.delegateAttendance.filter(
        //   (a) => a.packageCode === this.$route.params.package
        // ).length
      );
    },

    filterPackage: function (delegatePackages) {
      return delegatePackages
        .filter((d) => d.packageCode === this.$route.params.package)
        .shift();
    },

    toggleFullscreen: function () {
      if (this.fullscreen) {
        document.exitFullscreen();
        this.fullscreen = false;
      } else {
        document.documentElement.requestFullscreen();
        this.fullscreen = true;
      }
    },

    onScan: function () {
      if (this.prevTerm !== "") {
        this.scanned = this.scanned.replace(this.prevTerm, "");
      }

      this.prevTerm = this.scanned.trim().replace(/['"]+/g, "");

      this.getDelegate({
        delegateNo: this.scanned,
        eventCode: this.$route.params.event,
        packageCode: this.$route.params.package,
      });
    },

    getDelegate: function (delegate) {
      this.$store.dispatch("Dashboard/getDelegate", delegate);
    },

    toggle() {
      this.fullscreen = !this.fullscreen;
    },

    checkIn: function (delegate) {
      this.$store.dispatch("Dashboard/getDelegateAttendance", delegate);
      this.delegate = delegate;
      this.dialog = true;
    },

    submit: function () {
      this.$store.dispatch("Dashboard/submitAttendance", {
        eventNo: this.delegate.eventNo,
        bookingNo: this.delegate.bookingNo,
        delegateNo: this.delegate.delegateNo,
        packageCode: this.event.eventPackageCost[0].packageNo,
        consumedDate: moment().format("YYYY-MM-DD"),
        email: this.delegate.eventDelegate.email,
        phone: this.delegate.eventDelegate.phoneNo,
        event: this.event.description,
        startDate: this.event.startDate,
      });
      this.dialog = false;
      this.fetchData();
      this.packageStats();
    },

    getTag: function (report, delegateNo) {
      this.$store.dispatch("Dashboard/getQrTag", {
        report: report,
        delegateNo: delegateNo,
      });
    },

    getFingerprint: function (data) {
      this.$store.dispatch("Biometric/verifyFingerprint", data);
    },

    fetchData(cursor) {
      this.$store.dispatch("Dashboard/getEventDelegates", {
        eventCode: this.$route.params.event,
        packageCode: this.$route.params.package,
        cursor: cursor || "",
        search: this.term || "",
      });
    },

    packageStats() {
      this.$store.dispatch("Dashboard/getPackageStats", {
        eventCode: this.$route.params.event,
        packageCode: this.$route.params.package,
      });
    },
  },

  watch: {
    qrTag: {
      handler: function (n) {
        if (n) this.downloadFile(n, "Event Ticket.pdf");
      },
    },
    hasCheckedIn(newVal) {
      if (newVal) {
        this.$store.commit("Dashboard/SET_ALERT", {
          status: "success",
          message: `${this.delegate.name} is checked in successfully`,
        });
        this.$store.commit("Dashboard/SET_DELEGATE_ATTENDANCE", false);
      }
    },
  },
};
</script>

<style scoped></style>
